1
<template>
  <div>
    <form-wizard
      color="#28c76f"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      skip-button-text="Skip"
      class="steps-transparent mb-5"
      @on-complete="addCompany"
    >
      <!-- Company detail tab -->
      <tab-content title="Company Details" icon="feather icon-file-text">
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col cols="12" class="mb-2">
              <div class="d-flex">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="24"
                  class="mr-2"
                  @click="goBack"
                  style="cursor: pointer"
                />

                <div>
                  <h5 class="mb-0">Company Details</h5>
                  <small class="text-muted">
                    Enter Your Company Details.
                  </small>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Company Name<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="companyData.company_name"
                    name="company_name"
                    placeholder="Enter Company Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Email<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="companyData.company_email_id"
                    name="company_email_id"
                    placeholder="Enter Company Email"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- address information -->
          <h3 class="mb-2 mt-2 top-header">Address Information</h3>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <template v-slot:label>
                  Address<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required|Address"
                >
                  <b-form-textarea
                    name="company_address"
                    v-model="companyData.company_address"
                    placeholder="Enter Company Address"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  State<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" State Name"
                    rules="required"
                  >
                    <v-select
                      v-model="companyData.company_selectedState"
                      :options="stateList"
                      label="state_name"
                      :reduce="(e) => e.id"
                      @input="getDistrict"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  District<span class="text-danger">*</span>
                </template>
                <v-select
                  v-model="companyData.company_selectedDistrict"
                  :options="districtList"
                  label="district_name"
                  :reduce="(e) => e.id"
                  @input="getCity"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  City<span class="text-danger">*</span>
                </template>
                <v-select
                  v-model="companyData.company_selectedCity"
                  :options="cityList"
                  :reduce="(e) => e.id"
                  label="city_name"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Assign Owner -->
      <tab-content title="Assign Owner" icon="feather icon-map-pin">
        <validation-observer ref="simpleRules">
          <div class="mb-5">
            <h3 class="top-header mb-2">Owner Information</h3>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <template v-slot:label>
                    Name<span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="companyData.name"
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <template v-slot:label>
                    Email<span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      type="email"
                      v-model="companyData.email"
                      name="email"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <template v-slot:label>
                    Username<span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    rules="required"
                  >
                    <b-form-input
                      type="text"
                      v-model="companyData.user_name"
                      name="user_name"
                      placeholder="Enter Username"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group>
                  <template v-slot:label> Status </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <div class="d-flex mt-1">
                      <b-form-radio
                        v-model="companyData.status"
                        name="companyData.status"
                        value="Active"
                        class="mr-1"
                        >Active</b-form-radio
                      >
                      <b-form-radio
                        v-model="companyData.status"
                        name="companyData.status"
                        value="InActive"
                        >Inactive</b-form-radio
                      >
                    </div>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <template v-slot:label>
                    Password<span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-form-input
                      type="password"
                      v-model="companyData.password"
                      name="password"
                      placeholder="Enter Password"
                      autocomplete="new-password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <template v-slot:label>
                    Confirm Password<span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required"
                  >
                    <b-form-input
                      type="password"
                      v-model="companyData.cpassword"
                      name="cpassword"
                      placeholder="Confirm Password"
                          autocomplete="new-password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group>
                  <template v-slot:label>
                    Phone<span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    :rules="{
                      required: true,
                      integer: true,
                      min: 10,
                    }"
                  >
                    <b-form-input
                      type="number"
                      v-model="companyData.phone_no"
                      name="phone_no"
                      placeholder="Enter Phone No"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group class="mb-1">
                  <template v-slot:label> Profile Image </template>
                  <!-- <validation-provider
										#default="{ errors }"
										name="Profile Image"
										rules="required"
									> -->
                  <b-form-file
                    v-model="companyData.user_image"
                    plain
                    @change="onFileChange"
                  />

                  <!-- <small class="text-danger">{{ errors[0] }}</small>
									</validation-provider> -->
                </b-form-group>
                <div v-if="url">
                  <div class="profile-image">
                    <img :src="url" height="90" />
                    <div class="remove-image">
                      <feather-icon
                        icon="TrashIcon"
                        size="16"
                        class="deleteIcon"
                        @click="resetImage"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- address information -->
            <h3 class="top-header">Address Information</h3>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <template v-slot:label>
                    Address<span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Address"
                    rules="required"
                  >
                    <b-form-textarea
                      type="text"
                      v-model="companyData.address"
                      name="address"
                      placeholder=""
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <template v-slot:label>
                    State<span class="text-danger">*</span>
                  </template>
                  <div class="form-label-group">
                    <validation-provider
                      #default="{ errors }"
                      name="State Name"
                      rules="required"
                    >
                      <v-select
                        v-model="companyData.selectedState"
                        :options="stateList"
                        label="state_name"
                        :reduce="(e) => e.id"
                        @input="getDistrict"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <template v-slot:label>
                    District<span class="text-danger">*</span>
                  </template>
                  <v-select
                    v-model="companyData.selectedDistrict"
                    :options="districtList"
                    label="district_name"
                    :reduce="(e) => e.id"
                    @input="getCity"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <template v-slot:label>
                    City<span class="text-danger">*</span>
                  </template>
                  <v-select
                    v-model="companyData.selectedCity"
                    :options="cityList"
                    :reduce="(e) => e.id"
                    label="city_name"
                  />
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
            </b-row>
          </div>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { required, email } from "@validations";
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTabs,
  BTab,
  BCardText,
  BFormSelect,
  BFormCheckbox,
  BFormRadio,
  BFormFile,
} from "bootstrap-vue";
import AssignUSer from "./AssignUser.vue";
import axiosIns from "@/libs/axios";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormTextarea,
    BButton,
    BTabs,
    BTab,
    BCardText,
    AssignUSer,
    BFormSelect,
    BFormCheckbox,
    BFormRadio,
    BFormFile,
  },
  data() {
    return {
      url: null,
      emailValue: "",
      name: "",
      required,
      email,
      stateList: [],
      districtList: [],
      cityList: [],
      selectedState: null,
      selectedDistrict: null,
      selectedCity: null,
      user_image: null,
      company_name: null,
      company_address: null,
      company_email_id: null,
      companyData: {
        name: null,
        email: null,
        user_name: null,
        password: null,
        status: "Active",
        phone_no: null,
        address: null,
        company_email_id: null,
        company_address: null,
        company_name: null,
        company_selectedState: null,
        company_selectedDistrict: null,
        company_selectedCity: null,
      },
    };
  },
  created: function () {
    this.getState();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getState() {
      axiosIns
        .get(`web/get-state-list`)
        .then((response) => {
          this.stateList = response.data.state_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getDistrict(id) {
      axiosIns
        .get(`web/get-district-list?state_id=${id}`)
        .then((response) => {
          this.districtList = response.data.districts_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getCity(id) {
      axiosIns
        .get(`web/get-city-list?district_id=${id}`)
        .then((response) => {
          this.cityList = response.data.city_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    resetImage() {
      this.url = "";
    },
    addCompany() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          var data = new FormData();
          this.companyData.user_role = "owner";
          let formData = new FormData();
          let form_data = this.objectToFormData(this.companyData, formData);

          axiosIns
            .post(`web/companies`, form_data)
            .then((res) => {
              res;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Company Added Successfully `,
                },
              });
              this.$router.push({
                path: `/apps/company`,
              });
            })
            .catch((error) => {
              const data = error.response.data.errors;
              data, "data";
              let arr = Object.values(data).flat().toString(",");

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: arr,
                },
              });
            });
        }
      });
    },
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.profile-image {
  position: relative;
  border: 1px dashed #d9d9d9;
  height: 100px;
  width: 100px;
  padding: 5px 5px;
  text-align: center;
  border-radius: 5px;
  margin-top: 2rem;
}
.deleteIcon {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.remove-image {
  position: absolute;
  top: -7px;
  right: -10px;
  box-shadow: 0 9px 16px 0 rgba(24, 28, 50, 0.25) !important;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: #3f4254;
  background-color: #fff;
  border-color: #fff;
}
@import "@core/scss/vue/pages/nav-role.scss";
</style>
