<template>
	<validation-observer ref="simpleRules">
		<b-form @submit="addForm" method="post">
			<h3 class="mb-2 top-header">Basic Information</h3>
			<b-row>
				<b-col md="6">
					<b-form-group label="Name" label-for="Name">
						<b-form-input
							v-model="formData.name"
							name="name"
							type="text"
							placeholder="Enter Name"
						/>
					</b-form-group>
				</b-col>
				<b-col md="6">
					<b-form-group label="Email" label-for="email">
						<validation-provider
							#default="{ errors }"
							name="Email"
							rules="required"
						>
							<b-form-input
								type="email"
								v-model="formData.email"
								name="email"
								placeholder="email"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-col>
				<b-col md="6">
					<b-form-group label="Username" label-for="Username">
						<b-form-input
							type="text"
							v-model="formData.user_name"
							name="user_name"
							placeholder="Enter Username"
						/>
					</b-form-group>
				</b-col>

				<b-col md="6">
					<b-form-group label="Status" label-for=" status">
						<div class="d-flex mt-1">
							<b-form-radio
								v-model="formData.status"
								name="active"
								value="active"
								class="mr-2"
							>
								Active
							</b-form-radio>
							<b-form-radio
								v-model="formData.status"
								name="inactive"
								value="inactive"
							>
								Inactive
							</b-form-radio>
						</div>
					</b-form-group>
				</b-col>
				<b-col md="6">
					<b-form-group label="Password" label-for=" Password">
						<b-form-input
							type="password"
							v-model="formData.password"
							name="password"
							placeholder="Enter Password"
						/>
					</b-form-group>
				</b-col>
				<b-col md="6">
					<b-form-group label="Confirm Password" label-for="Confirm Password">
						<b-form-input
							type="password"
							v-model="formData.cpassword"
							name="cpassword"
							placeholder="Confirm Password"
						/>
					</b-form-group>
				</b-col>

				<b-col md="6">
					<b-form-group label="Phone" label-for="Phone No">
						<b-form-input
							type="phone"
							v-model="formData.phone_no"
							name="phone_no"
							placeholder="Enter Phone No"
						/>
					</b-form-group>
				</b-col>
				<b-col md="6">
					<b-form-group label="Profile Image" label-for="" class="mb-1">
						<b-form-file v-model="user_image" plain />
					</b-form-group>
				</b-col>
			</b-row>
			<!-- address information -->
			<h3 class="mb-2 mt-2 top-header">Address Information</h3>
			<b-row>
				<b-col md="12">
					<b-form-group label="Address" label-for="Address">
						<b-form-textarea
							type="text"
							v-model="formData.address"
							name="address"
							placeholder=""
							rows="3"
						/>
					</b-form-group>
				</b-col>
				<b-col md="6">
					<b-form-group label="State" label-for="state">
						<b-form-select
							v-model="selectedState"
							:options="stateList"
							value-field="state_code"
							text-field="state_name"
							v-on:change="getDistrict"
						/>
					</b-form-group>
				</b-col>
				<b-col md="6">
					<b-form-group label="District" label-for="district">
						<b-form-select
							v-model="selectedDistrict"
							:options="districtList"
							value-field="district_code"
							text-field="district_name"
							v-on:change="getCity"
						/>
					</b-form-group>
				</b-col>
				<b-col md="6">
					<b-form-group label="City" label-for="city">
						<b-form-select
							v-model="selectedCity"
							:options="cityList"
							value-field="city_code"
							text-field="city_name"
						/>
					</b-form-group>
				</b-col>

				<!-- submit and reset -->
			</b-row>
		</b-form>
	</validation-observer>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import * as Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BButton,
	BCardText,
	BForm,
	BFormGroup,
	BFormInput,
	BInputGroup,
	BInputGroupPrepend,
	BFormTextarea,
	BFormCheckbox,
	BPopover,
	BFormSelect,
	BFormDatepicker,
	BFormRadio,
	BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { BASE_URL } from "@core/common/constants";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BButton,
		BCardText,
		BForm,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,
		BFormTextarea,
		BFormCheckbox,
		BPopover,
		BFormRadio,
		BFormSelect,
		BFormDatepicker,
		flatPickr,
		vSelect,
		Logo,
		BFormFile,
	},
	props: {
		permissionName: {
			type: String,
		},
	},
	data() {
		return {
			stateList: null,
			districtList: null,
			cityList: null,
			selectedState: null,
			selectedDistrict: null,
			selectedCity: null,
			user_image: null,
			formData: {
				name: null,
				email: null,
				user_name: null,
				password: null,
				status: null,
				phone_no: null,
				address: null,
			},
			user_role: window.location.pathname.split("/")[2],
		};
	},
	created: function () {
		this.getState();
	},
	methods: {
		getState() {
			axiosIns
				.get(`web/getStateList`)
				.then((response) => {
					response.data, "statelist";
					this.stateList = response.data.state_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getDistrict(state_code) {
			state_code;
			axiosIns
				.get(`web/getDistrictList`, {
					params: state_code,
				})
				.then((response) => {
					this.districtList = response.data.districts_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getCity(district_code) {
			axiosIns
				.get(`web/getCityList`, {
					params: district_code,
				})
				.then((response) => {
					this.cityList = response.data.city_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addForm(e) {
			e.preventDefault();
			var data = new FormData();

			data.append("name", this.formData.name);
			data.append("user_name", this.formData.user_name);
			data.append("password", this.formData.password);
			data.append("email", this.formData.email);
			data.append("status", this.formData.status);
			data.append("phone_no", this.formData.phone_no);
			data.append("city", this.selectedState);
			data.append("district", this.selectedDistrict);
			data.append("state", this.selectedCity);
			data.append("address", this.formData.address);
			data.append("user_role", this.user_role);
			data.append("user_image", this.user_image);
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					axiosIns
						.post(`web/storeUser`, data, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									// title: `Welcome `,
									icon: "CoffeeIcon",
									variant: "success",
									text: `data Successfully Added `,
								},
							});
							// this.$router.push({
							//   path: `/apps/${this.user_role.toLowerCase()}/list`,
							// });
						})
						.catch((error) => {
							const data = error.response.data.errors;
							// (data, "data");
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									// title: `Welcome `,
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.top-header {
	border-bottom: 1px solid #38c06c;
	padding: 1rem 0rem;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
	background-color: $product-details-bg;
}

.form-item-action-col {
	width: 27px;
}

.repeater-form {
	// overflow: hidden;
	transition: 0.35s height;
}

.v-select {
	&.item-selector-title,
	&.payment-selector {
		background-color: #fff;

		.dark-layout & {
			background-color: unset;
		}
	}
}

.dark-layout {
	.form-item-section {
		background-color: $theme-dark-body-bg;

		.row .border {
			background-color: $theme-dark-card-bg;
		}
	}
}
</style>
